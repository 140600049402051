import React, { useState, useEffect } from 'react';
// import sections
import CountDown from '../components/sections/CountDown';
import Purchase from '../components/sections/Purchase';

import FeaturesTiles from '../components/sections/FeaturesTiles';
import FeaturesSplit from '../components/sections/FeaturesSplit';
import Testimonial from '../components/sections/Testimonial';
import Cta from '../components/sections/Cta';
import Web3 from 'web3'
import { notification } from 'antd'
import * as AntfToken from '../utils/AntfToken.json'
import 'antd/dist/antd.css';
const Home = (props) => {
  // const [web3, setWeb3] = useState(false);
  const [account, setAccount] = useState(null);
  const [web3, setWeb3] = useState(null)
  const [token, setToken] = useState(null)
  const [isAlert, setIsAlert] = useState(false)
  let web3js = new Web3("https://bsc-dataseed1.ninicoin.io/");

  let contract = null;
  const contractAddress = "0xf00598af0CCf2D43613414F36f40123337f56E71";

  const initContract = (web3js) => {
    if (!web3js) {
      return;
    }
   //console.log('init contract');
    contract = new web3js.eth.Contract(AntfToken.abi, contractAddress);
  }

  useEffect(() => {

    if (window.ethereum) {
      // setWeb3(new Web3(window.ethereum))
      web3js = new Web3(window.ethereum)
      try {
        window.ethereum.enable().then(function () {
          web3js.eth.getAccounts((error, accounts) => {
            if (error) {
              console.log(error);
              setAccount(null)
            } else {
              if (!account) {
                setAccount(accounts[0])
                setWeb3(web3js)
                initContract(web3js)
                setToken(contract)
              }
            }
          });
          // User has allowed account access to DApp...
        });
      } catch (e) {
        // User has denied account access to DApp...
      }
    }
    // Legacy DApp Browsers
    else if (window.web3) {
      // setWeb3(new Web3(window.web3.currentProvider))
      web3js = new Web3(window.web3.currentProvider)
      if (!web3) {
        setWeb3(web3js)
        initContract(web3js)
        setToken(contract)
      }
    }
    //  Non-DApp Browsers
    else {
      try {
        setWeb3(web3js)
        initContract(web3js)
        setToken(contract)
      } catch (error) {
       console.log(error);
      }

    }
    //console.log('props', props)
  });


  return (
    <>
      <CountDown className="illustration-section-01" />
      <Purchase {...props} token={token} web3={web3} account={account} className="illustration-section-01" />
      {/* <FeaturesTiles /> */}
      {/* <FeaturesSplit invertMobile topDivider imageFill className="illustration-section-02" />
      <Testimonial topDivider /> */}
      {/* <Cta split /> */}
    </>
  );
}

export default Home;